/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import WebsiteHeader from "../website-header/website-header"
import WebsiteFooter from "../website-footer/website-footer"
import styles from "./website-layout.module.scss"
import classNames from "classnames"

const WebsiteLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleWebsiteQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isSticky, setSticky] = useState(false)

  function setStickyHeader() {
    if (window.pageYOffset > 0) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", setStickyHeader)
  }, [])

  return (
    <>
      <div
        className={classNames(
          styles.wrapper,
          children[0].props.title === "Forest History Association of Alberta"
            ? styles.homeWrapper
            : ""
        )}
      >
        <div
          className={classNames(
            styles.header,
            isSticky ? styles.stickyHeader : ""
          )}
        >
          <WebsiteHeader siteTitle={data.site.siteMetadata.title} />
        </div>
        <main> {children} </main>
        <WebsiteFooter />
      </div>
    </>
  )
}

WebsiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WebsiteLayout
